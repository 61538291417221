import {
  Button,
  Flex,
  Graphic,
  Heading,
  Icon,
  IconSkeleton,
  Image,
  Link,
  LinkButton,
  Price,
  Skeleton,
  Stack,
  StackProps,
  StarRating,
  Text,
  useToast,
  Box,
} from '@thirstycamel/ui'
import React, { memo, useCallback, useState } from 'react'
import type { CoreProductEntity } from '@ts/core/src/modules/coreproduct/coreproduct.entity'
import { ProductType } from '@ts/core/src/modules/coreproduct/coreproduct.types'

import { useUser } from '../../hooks/useUser'
import { useActions, useStore } from '../../store/hooks'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import imageProxy from '../../utils/imageProxy'
import ProductBadge from '../ProductBadge'
import { State } from '../../types/state'
import { useRouter } from 'next/router'

// Util function to format URLs by replacing spaces and periods with "-"
const formatUrlSegment = (segment: string = ''): string => {
  return segment.replace(/\s+|%20|\./g, '-').toLowerCase()
}

export const ProductCardPlaceholder = (props: ProductCardProps) => {
  return (
    <Stack
      pt={4}
      pb={8}
      px={[6, null, 8]}
      spacing={6}
      border="2px"
      borderColor="gray.100"
      borderRadius="lg"
      align="center"
      bg="white"
      {...props}
    >
      <Flex justify="space-between" w="100%">
        <Stack />

        <Skeleton h="1.125rem" maxW="5rem" w="100%" />
      </Flex>

      <Flex h={['120px', '140px', '160px', '200px']} w="100%" justify="center">
        <IconSkeleton
          name="bottle-outline"
          height={['6.5rem', '8rem', '9.5rem', '12rem']}
          maxWidth={['6.5rem', '8rem', '9.5rem', '12rem']}
          w="100%"
          animation="0.8s linear infinite alternate"
        />
      </Flex>

      <Stack spacing={5} align="center" w="100%">
        <Stack spacing={3} align="center" w="100%">
          <Skeleton h="1.5rem" maxW="5rem" w="100%" />
          <Skeleton h="1rem" maxW="8rem" w="100%" />
        </Stack>

        <Skeleton h="1rem" maxW="5rem" w="100%" />
      </Stack>

      <Skeleton h="2.5rem" w="100%" />
    </Stack>
  )
}

export interface ProductCardProps extends StackProps {
  product?: CoreProductEntity
}

function shouldShowCamelCashSA(product: CoreProductEntity): boolean {
  if (!product?.bonusCamelCashAmountSA) {
    return false
  }

  let started = false
  let ended = false

  if (!product?.bonusCamelCashStartSA) {
    started = true
  } else {
    started = new Date().getTime() > new Date(product?.bonusCamelCashStartSA).getTime()
  }

  if (product?.bonusCamelCashExpirySA) {
    ended = new Date().getTime() > new Date(product?.bonusCamelCashExpirySA).getTime()
  }

  return started && !ended
}

function shouldShowCamelCashNT(product: CoreProductEntity): boolean {
  if (!product?.bonusCamelCashAmountNT) {
    return false
  }

  let started = false
  let ended = false

  if (!product?.bonusCamelCashStartNT) {
    started = true
  } else {
    started = new Date().getTime() > new Date(product?.bonusCamelCashStartNT).getTime()
  }

  if (product?.bonusCamelCashExpiryNT) {
    ended = new Date().getTime() > new Date(product?.bonusCamelCashExpiryNT).getTime()
  }

  return started && !ended
}

export function shouldShow1kDayBadge(product: CoreProductEntity): boolean {
  return (
    product?.showWin1kPromo &&
    product?.win1kPromoStartDate &&
    product?.win1kPromoEndDate &&
    new Date().getTime() > new Date(product.win1kPromoStartDate).getTime() &&
    new Date().getTime() < new Date(product.win1kPromoEndDate).getTime()
  )
}

export const ProductCard = ({ product, ...restProps }: ProductCardProps) => {
  /* Cart */
  const { isFetching, cartId } = useStore(s => s.cart)
  const toast = useToast()
  const router = useRouter()
  const { addProductToCart, createCart } = useActions(s => s.cart)

  /* Store */
  const selectedStore = useStore(s => s.store.selectedStore)
  const location = useStore(s => s.location)

  const region = selectedStore?.region || location.region || 'VIC'
  const openPopout = useActions(s => s.store.openPopout)

  const [isAddingToCart, setAddingToCart] = useState(false)

  const { user } = useUser()

  const sku = `${selectedStore?.slug}_${product?.slug}`

  const price = product?.pricing?.price
  let specialPrice = product?.specialPricing?.price

  if (product?.offerPrice && product?.offerPrice < specialPrice) {
    specialPrice = product.offerPrice
  }

  const handleAddToCart = useCallback(async () => {
    if (!selectedStore) {
      openPopout()
      return
    }

    setAddingToCart(true)
    try {
      await addProductToCart({ product, qty: 1 })
    } catch (error) {
      toast({
        title: getNiceErrorMessage(error),
        status: 'error',
      })
    }
    setAddingToCart(false)
  }, [addProductToCart, sku, cartId, selectedStore, openPopout])

  let href = '/product/[group]/[slug]'
  let hrefAs = `/product/${formatUrlSegment(product?.productGroup?.brand?.name)}/${formatUrlSegment(
    product?.slug,
  )}`

  const isBundleProduct = router.query?.bundleGroup

  if (isBundleProduct) {
    href = `${href}?bundleGroup=${formatUrlSegment(router.query?.bundleGroup as string)}`
    hrefAs = `${hrefAs}?bundleGroup=${formatUrlSegment(router.query?.bundleGroup as string)}`
  }

  const isNew = product?.newUntil && new Date(product.newUntil).getTime() > new Date().getTime()
  const isNewActive = isNew && product?.newBadgeActiveRegions.includes(region as State)

  const hasCustomBadge =
    (product?.badge && product.badge.activeRegions.includes(region as State)) || false
  const isBundle = product?.type === ProductType.BUNDLE
  const canAddToCart =
    (product?.pricing?.price || product?.specialPricing?.price) &&
    (selectedStore?.isPickupReady || selectedStore?.isDeliveryReady)

  const isEveryDayValue =
    product?.specialPricing?.type == 'PRICELIST' && !product?.specialPricing?.isSpecial
  const isMemberOnly = product?.offerPrice
  const isSpecial = specialPrice && !isEveryDayValue

  const showEveryDayValuePrice =
    isEveryDayValue &&
    (!product?.offerPrice || product?.offerPrice > product?.specialPricing?.price)

  const showMemberPrice = isMemberOnly && (!isEveryDayValue || specialPrice >= product?.offerPrice)

  return (
    <Stack
      pt={4}
      pb={8}
      px={[6, null, 8]}
      spacing={6}
      justify="space-between"
      align="center"
      textAlign="center"
      border="2px"
      borderColor="gray.100"
      borderRadius="lg"
      bg="white"
      position="relative"
      maxWidth="400px"
      {...restProps}
    >
      {showEveryDayValuePrice ? (
        <ProductBadge type="everyday" />
      ) : isSpecial ? (
        <ProductBadge type="save" price={product?.pricing?.price - specialPrice} />
      ) : showMemberPrice ? (
        <ProductBadge type="member" />
      ) : hasCustomBadge ? (
        <ProductBadge type="image" src={product?.badge?.image.filename} />
      ) : shouldShow1kDayBadge(product) ? (
        <Box position="absolute" top={1} left={1} color="white" userSelect="none">
          <Image src={`/static/images/win1kaday.png`} width="100px" />
        </Box>
      ) : isNew && isNewActive ? (
        <ProductBadge type="new" />
      ) : isBundle ? (
        <ProductBadge type="bundle" />
      ) : null}

      {region === 'SA' && shouldShowCamelCashSA(product) ? (
        <Box position="absolute" top={1} left={1} color="white" userSelect="none">
          <Image
            src={`/static/images/camel-cash/${product?.bonusCamelCashAmountSA}.png`}
            width="64px"
          />
        </Box>
      ) : null}

      {region === 'NT' && shouldShowCamelCashNT(product) ? (
        <Box position="absolute" top={1} left={1} color="white" userSelect="none">
          <Image
            src={`/static/images/camel-cash/${product?.bonusCamelCashAmountNT}.png`}
            width="64px"
          />
        </Box>
      ) : null}

      <Stack spacing={4} align="center" w="100%">
        <Stack isInline align="center" justify="flex-end" spacing={1} w="100%">
          {/* <StarRating value={product?.rating} size="xs" /> */}
          <StarRating value={product?.productGroup?.rating || 0} size="xs" />
          <Text color="gray.500" fontWeight="bold" fontSize="xs">
            ({product?.productGroup?.numberOfReviews || 0})
          </Text>
          {user?.favouriteProducts?.find(p => p.id === product?.id) && (
            <Icon name="heart" color="pink.500" pl={2} fontSize="2xl" />
          )}
        </Stack>

        <Link href={href} hrefAs={hrefAs}>
          {product?.media?.[0]?.filename ? (
            <Image
              src={imageProxy(product?.media?.[0]?.filename, { height: 240 })}
              height={['120px', '140px', '160px', '200px']}
              objectFit="contain"
            />
          ) : (
            <Graphic
              name="camel"
              height={['120px', '140px', '160px', '200px']}
              width="80%"
              mx="auto"
              opacity={0.15}
            />
          )}
        </Link>

        <Stack spacing={3} w="100%">
          <Link href={href} hrefAs={hrefAs}>
            <Stack spacing={1}>
              <Heading textTransform="uppercase">
                {isBundle ? product.bundle?.title : product?.productGroup?.brand?.name}
              </Heading>

              <Text isTruncated={isBundle}>
                {isBundle ? product.bundle?.description : product?.productGroup?.name}
              </Text>
            </Stack>
          </Link>

          <Stack spacing={1}>
            {showMemberPrice && (
              <Heading fontSize="xs" textTransform="uppercase" color="gray.600">
                MEMBER PRICE
              </Heading>
            )}

            <Flex align="center" justify="center">
              {showMemberPrice && (
                <Heading as={Price} color={'pink.500'}>
                  {product?.offerPrice}
                </Heading>
              )}

              {!showMemberPrice && price && !specialPrice && (
                <Heading as={Price} color={'pink.500'}>
                  {price}
                </Heading>
              )}

              {!showMemberPrice && specialPrice && (
                <Heading as={Price} color="pink.500" ml={price && !specialPrice ? 3 : 0}>
                  {specialPrice}
                </Heading>
              )}
            </Flex>

            <Heading fontSize="xs" textTransform="uppercase" color="gray.600">
              {isBundle
                ? product.bundle?.isMultibuy
                  ? product.bundle?.multibuyLabel || 'Multibuy'
                  : 'Bundle'
                : product?.factorDescription}
            </Heading>
          </Stack>
        </Stack>
      </Stack>

      {canAddToCart && !isBundle ? (
        <Button
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
            return false
          }}
          onClick={handleAddToCart}
          variantColor="pink"
          px={6}
          isLoading={isAddingToCart}
          size="sm"
        >
          Add to cart
        </Button>
      ) : selectedStore?.externalURL ? (
        <LinkButton
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
            return false
          }}
          href={`${selectedStore.externalURL}${
            product?.santExternalID ? `/lines/${formatUrlSegment(product.santExternalID)}` : ''
          }`}
          isExternal
          variantColor="pink"
          px={6}
          size="sm"
        >
          Shop Now
        </LinkButton>
      ) : (
        <LinkButton
          onMouseDown={e => {
            e.stopPropagation()
            e.preventDefault()
            return false
          }}
          href={href}
          hrefAs={hrefAs}
          variantColor="pink"
          px={6}
          size="sm"
        >
          View {isBundle ? 'Bundle' : 'Product'}
        </LinkButton>
      )}
    </Stack>
  )
}

export default memo(ProductCard)
